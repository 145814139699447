import React,{Component} from 'react';
//引入路由配置页
import RouterIndex from "./router/index";
import "./styles/reset.scss"

class App extends Component{
	render(){
		 return <RouterIndex />
	}
}

export default App;
