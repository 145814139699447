import React,{Component} from "react";//引入react
import {  List,TextareaItem, InputItem,Result, Icon } from 'antd-mobile';
import Axios from 'axios';
import 'antd-mobile/dist/antd-mobile.css';
import "../../styles/style.scss"


class Order extends Component{
	componentDidMount(){
		document.title="预约成功"
	}
	//渲染
	render() {
		const data=JSON.parse(sessionStorage.getItem('info'))
	
		return ( <div className="order">
			<Result
				img={<Icon type="check-circle" className="spe" style={{ fill: '#1F90E6' }} />}
				title="预约成功"/>
				<List renderHeader={() => ''}>
						<InputItem type='money'  moneyKeyboardAlign="right" value={data.company} disabled>公司</InputItem>
				</List>
				<List renderHeader={() => ''}>
						<InputItem type='money'  moneyKeyboardAlign="right" value={data.phone} disabled>手机号码</InputItem>
				</List>
				<List renderHeader={() => ''}>
						<InputItem type='money'  moneyKeyboardAlign="right" value={data.email} disabled>邮箱</InputItem>
				</List>
				<List renderHeader={() => ''}>
						<InputItem type='money'  moneyKeyboardAlign="right" value={data.num} disabled>参观人数</InputItem>
				</List>
				<List renderHeader={() => ''}>
						<InputItem type='money'  moneyKeyboardAlign="right" value={data.time} disabled>预约时间</InputItem>
				</List>
				<List renderHeader={() => ''}>
						<InputItem type='money'  moneyKeyboardAlign="right" value={data.floor} disabled>参观楼层</InputItem>
				</List>
				<List renderHeader={() => ''}>
				  <TextareaItem title="备注信息" value={data.remarks} autoHeight disabled/>
				</List>

		</div>);

	}
}
//导出
export default Order;