import React,{Component} from "react";//引入react
import {Switch,Route,Redirect } from "react-router-dom";

//引入页面
import Index from "../view/index/index"
import Forms from "../view/subscribe/forms"
import Order from "../view/subscribe/order"
//路由配置
class RouterIndex extends Component{
	render(){
		return(
		<Switch>
			<Route path="/" exact render={()=>(<Redirect to="/index" />)} />
			<Route path="/index" exact component={Index}/>
			<Route path="/forms" exact component={Forms}/>
			<Route path="/order" exact component={Order}/>
		</Switch>
		)	
	}
}

//导出
export default RouterIndex;