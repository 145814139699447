import React,{Component} from "react";//引入react
import {Button } from 'antd-mobile';
import 'swiper/css/swiper.min.css'
import Swiper from 'swiper/js/swiper.js'
import "../../styles/animation.scss"
import "../../styles/style.scss"
import hallData from "../../data/hallData"

//首页
class Index extends Component{
	//创建组件
	constructor(props) {
		super(props);
		this.state = {
		  musicOff:true, 
		}
	}
	//组件挂载完成
	componentDidMount(){
		this.initViewSwiper()	
	}
	//音乐播放/暂停
	musicHandle=()=>{
		if(this.refs.myAudio.paused){
			this.refs.myAudio.play();
			   this.setState({musicOff:true})
		}else{
			this.refs.myAudio.pause();
				this.setState({musicOff:false})
		}
	}
	//滑屏切换
	initViewSwiper=()=>{
		this.mySwiper=new Swiper(this.refs.views,{
			slidesPerView: 'auto',
			direction: 'vertical',
			keyboardControl: true,
			mousewheelControl: true,
			observer: true, //修改swiper自己或子元素时，自动初始化swiper
			observeParents: true, //修改swiper的父元素时，自动初始化swiper
		    })
	}
	//预约跳转
	formsHandle=()=>{
		this.props.history.push('/forms')
	}
	//渲染
	render(){
		//一层金融厅
		let floorFirst=hallData.floorFirst.map((item,index)=>{
			return(
				<li key={item.id}>
					<div className="pic">
						<img src={item.img} alt="" />
					</div>
					<div className="title" >
						<p>{item.title}</p>
						<p dangerouslySetInnerHTML={{ __html:item.subTitle}}></p>
					</div>
				</li>
			)
		})
		//一层金融厅
		let floorTwo=hallData.floorTwo.map((item,index)=>{
			return(
				<li key={item.id}>
					<div className="pic">
						<img src={item.img} alt="" />
					</div>
					<div className="title" >
						<p>{item.title}</p>
						<p dangerouslySetInnerHTML={{ __html:item.subTitle}}></p>
					</div>
				</li>
			)
		})
		
		return (
			<div className="home">
				<audio ref="myAudio"  src={require("../../img/music.mp3")} autoPlay  loop  >
				　　<track kind="captions" />您的浏览器不支持 audio 元素。
				</audio>
				<div className="music "
					className={`music ${this.state.musicOff?"musicPlay":null}`}
					onClick={this.musicHandle}>
					<img src={require("../../img/music.png")} alt="" />
				</div>
				<div className="swiper-container" ref="views">
					<div className="swiper-wrapper">
						<div className="swiper-slide view1">
							<div className="introduce">
								<em className="border-horn"></em>
								<div><img src={require("../../img/logo.png")} alt="扬子江新金融示范区" /></div>
								<span>南京江北新区CBD启航区</span>
								<h3>扬子江新金融示范区</h3>
								<p className="describe">扬子江新金融示范区位于南京江北新区中央商务区滨江七里河片区，利用装配式建筑快速建造，紧扣江北新区“两城一中心”战略规划的“一中心”，围绕新金融、新商业、新空间、新社交的建设理念，承载前期新金融产业，提升中央商务区形象，聚集人气，提高新区建设品质，是承载“新金融中心”的启航区。</p>
							</div>
							<div className="experience clearfix">
								<div className="fl">
									<img src={require("../../img/logo1.png")} alt="" />
									<img src={require("../../img/home2.png")} alt="" />
									<img src={require("../../img/home1.png")} alt="" />
								</div>
								<div className="fr">
									<img src={require("../../img/home3.png")} alt="" />
								</div>
							</div>
						</div>
						<div className="swiper-slide view2">
							<div className="pic"></div>
							<div className="introduce">
								<em className="border-horn"></em>
								<h3>展厅简介</h3>
								<span>Exhibition hall introduction</span>
								<p className="describe">扬子江新金融体验中心展陈面积2000平，主要分为金融科技厅和新金融企业展厅。内容主要从新金融定义、金融科技、新金融场景体验以及企业金融创新产品等方面介绍。</p>
							</div>
						
						</div>
						<div className="swiper-slide view3 hall-box animation3">
							<ul>{floorFirst}</ul>
							<h3 className="first-floor">
								<p>一层</p>
								<p>金融科技厅</p>
							</h3>
						</div>
						<div className="swiper-slide view4 hall-box">
							<ul>{floorTwo}</ul>
							<h3 className="first-floor">
								<p>二层</p>
								<p>企业展厅</p>
							</h3>
						</div>
						<div className="swiper-slide view5">
							<header>
								<img src={require("../../img/logo.png")} alt="" />
							</header>
							<div className="content">
								<div className="main">
									<div className="border-bg"></div>
									<div className="border-bg"></div>
									<dl>
										<dt>
											<p>扬子江</p>
											<p>新金融体验中心</p>
											<p>YANGZE NEW FINANCE EXPERIENCE CENTER</p>
										</dt>
										<dd className="forms-button">
											<Button type="ghost" className="am-button-borderfix" 
												onClick={this.formsHandle.bind(this)} inline>立即预约</Button>
										</dd>
									</dl>
						
								</div>
								<footer>
									<img src={require("../../img/logo2.png")} alt="" />
									<p>南京江北新区滨江大道296号2号楼</p>
								</footer>
							</div>
						
						</div>
					</div>
				</div>
			</div>
		)
	}
	
}

//导出
export default Index;
