let data={
	floorFirst:[
		{id:Math.random(),title:"新金融定义",subTitle:"NEW FINANCE",img:require("../img/hall3-1.png")},
		{id:Math.random(),title:"新金融科技",subTitle:"FINANCAL<br/>TECHNOLOGY",img:require("../img/hall3-2.png")},
		{id:Math.random(),title:"金融创新",subTitle:"FINANCAL<br/>INNOVATION",img:require("../img/hall3-3.png")},
		{id:Math.random(),title:"产业金融",subTitle:"INDUSTRIAL<br/>FINANCE",img:require("../img/hall3-4.png")},
	],
	floorTwo:[
		{id:Math.random(),title:"南京数字金融厅",subTitle:"NJDFI",img:require("../img/hall4-1.png")},
		{id:Math.random(),title:"华为智慧金融厅",subTitle:"HUAWEI<br/>SMART",img:require("../img/hall4-2.png")},
		{id:Math.random(),title:"平安金融科技厅",subTitle:"PINGAN<br/>FINANCIAL",img:require("../img/hall4-3.png")},
		{id:Math.random(),title:"云钞金融服务展厅",subTitle:"YUNCHAO<br/>FINANCIAL",img:require("../img/hall4-4.png")},
	
	]
}
//导出
export default data;