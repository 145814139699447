import React,{Component} from "react";//引入react
import {List, InputItem,Picker,DatePicker,TextareaItem,Toast,Button } from 'antd-mobile';
import { createForm } from 'rc-form';
import Axios from 'axios';
import 'antd-mobile/dist/antd-mobile.css';
import "../../styles/style.scss"

const nowTimeStamp = Date.now();
let minDate = new Date(nowTimeStamp - 1e7);
const maxDate = new Date(nowTimeStamp + 1e7);

//表单
class EditForms extends Component{
	componentDidMount(){
		document.title="预约参观"
	}
	//表单提交
	submit = () => {
	  function checkTime(i){
		 if (i<10) i="0" + i
		 return i;
	  }
	this.props.form.validateFields((error, value) => {  
		Object.keys(value).forEach(function(key){
		   if(value[key]==undefined) value[key]=''
		})	
		//去掉字符串空格
		let phone=value.phone
		while(phone.indexOf(" ")!=-1){
			phone=phone.replace(" ","");
		}
		
		//校验
		if(value.phone=='') {
			Toast.info('手机号不能为空!');
			return false;
		}
		
		if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone))) {
			 Toast.info('请输入有效合法的手机号码');
			return false;	 
		} 
		var reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$");
		if(value.email!=''&&!reg.test(value.email)){ 
		　　　　Toast.info("请输入有效合法的邮箱地址！");
		　　　　return false;
		　　}
		if(value.num=='') {
			Toast.info('请输入参观人数!'); 
			return false;
		}
		if(value.num<=0 ||value.num>1000 ) {
			Toast.info('参观人数区间为0~1000!'); 
			return false;
		}

		if(value.time=='') {
			Toast.info('请选择预约时间!'); 
			return false;
		}
		
		let date = new Date(value.time);
		value.time=date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + checkTime(date.getHours()) + ':' + checkTime(date.getMinutes())+ ':00';  
		var str=value.time;
		var arr = str.split(/[- : \/]/);
		console.log(arr)
		var hour = arr[3]; var min = arr[4]; var sec = arr[5];

		if (hour < 10) {
			Toast.info('预约时间限定每天10:00~17:00!'); 
			 return false;
		}

		if (hour >= 17) {
			if (min == 0 && sec == 0){
				//console.log("欢迎");
			}else{
				Toast.info('预约时间限定每天10:00~17:00!');
				 return false;	
			}
		}  
		 	  
		if(value.floor=='') {
			Toast.info('请选择参观楼层!'); 
			return false;
		}
	  
		if(!error){
	
			value.time= arr[0]+'-'+arr[1]+'-'+arr[2]+' '+arr[3]+':'+arr[4]
			value.floor=value.floor[0]
			value.phone=phone
	
			let H="";
			//let H="https://reserve.njna-cbd.com";
		
			 //本地存储数据
			  sessionStorage.setItem('info', JSON.stringify(value));
			  
			Axios.post(`${H}/api/newFinancial/order`,value)
				.then(res=>{
					if(res.data.data>0) {
						  //Toast.success('提交成功', 2);
						 // this.props.form.resetFields();	
						  //预约成功页
						  this.props.history.push('order')
					}
					else{
						Toast.fail(res.data.msg); 	
					} 
					 
				}).catch(error=>{
					Toast.fail(error); 	
				})
		}
	  
	});
	}

	//渲染
	render() {
	const { getFieldProps } = this.props.form;  
	const floorOptions = [
		 { value: '全部', label: '全部' },
		 { value: '一层', label: '一层' },
		 { value: '二层', label: '二层' }
	  ]	

	return ( <form className="forms">
				<List renderHeader={() => '公司'}>
					<InputItem 
						placeholder="请输入贵公司名称"
						{...getFieldProps('company')}
						clear></InputItem>
				</List>
				<List renderHeader={() => '手机号码'}>
					<InputItem
						type="phone"
						placeholder="请输入您的手机号码"
						{...getFieldProps('phone')}
						 clear></InputItem>
				</List>	
				<List renderHeader={() => '邮箱'}>
					<InputItem 
						type="email" 
						placeholder="请输入邮箱"  
						{...getFieldProps('email')}
						clear ></InputItem>
				</List>
				<List renderHeader={() => '参观人数'}>
					<InputItem 
						type="number" 
						maxLength="4"
						placeholder="请输入参观人数" 
						{...getFieldProps('num')}
						clear></InputItem>
				</List>
				<List  className="date-picker-list"  renderHeader={() => '预约时间(每天10:00~17:00)'}>
					<DatePicker 
							minDate={minDate}
					      
							{...getFieldProps('time')}>
							<List.Item ></List.Item>
					</DatePicker>
				</List>
				<List renderHeader={() => '参观楼层'}>
						<Picker getFieldProps
							{...getFieldProps('floor')}
							data={floorOptions} 
							cols={1}  
							className="forss">
						  <List.Item></List.Item>
						</Picker>
				</List>
				<List renderHeader={() => '备注信息'}>
					  <TextareaItem
						placeholder="填写备注内容" 
						{...getFieldProps('remarks')}
						autoHeight 
						labelNumber={5} />
				</List>
				<div className="forms-button">
					<Button 
						type="ghost"   
						className="am-button-borderfix"
						onClick={this.submit} inline>确认预约</Button>
				</div>
	  </form>);

	}
}

// 将form表单的api绑定到props,便于调用
const Forms = createForm()(EditForms);

//导出
export default Forms;
